import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getUserRegisterForms(params = {}) {
  return request({
    url: ENDPOINT.USER_REGISTER_FORMS,
    method: 'get',
    params
  })
}
